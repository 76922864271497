import React, { useEffect, useRef } from "react";
import CommonLayout from "../layouts/CommonLayout";
import CrStyle from "../assets/styles/ModuleStyle/course.module.css";
import { navScroller } from "../utils/subheaderscroller";
import LatestUpdates from "../components/LatestUpdates";
import Blogs from "../components/Blogs";
import TopUniversity from "../components/TopUniversity";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import CourseNameDetails from "../components/CourseComponents/CourseNameDetails";
import SubHeader from "../components/CourseComponents/SubHeader";
import JulyOfferWithCarousel from "../components/CourseComponents/JulyOfferWithCarousel";
import GetAssistanceBox from "../components/CourseComponents/GetAssistanceBox";
import UgcAdd from "../components/CourseComponents/UgcAdd";
import UniversitySummary from "../components/CourseComponents/UniversitySummary";
import CourseFee from "../components/CourseComponents/CourseFee";
import FacultyDetails from "../components/CourseComponents/FacultyDetails";
import CourseSideBar from "../components/CourseComponents/CourseSideBar";
import StudentManse2 from "../components/CourseComponents/StudentManse2";
import { apiBaseUrl, baseUrl } from "../config";
import { useParams } from "react-router-dom";
import { setCourse } from "../reducer/courseSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUniversity, setUniversityId } from "../reducer/universitySlice";
import Faqs from "../components/CourseComponents/Faqs";
import WriteAReview from "../components/CourseComponents/WriteAReview";

export default function CourseSpecial() {
  const dispatch = useDispatch();
  // const location = useLocation();
  const { courseName } = useParams();

  const mainUniversity = useSelector(
    (state) => state.mainuniversity?.university
  );
  const uniDetails = mainUniversity?.univercity_details;
  const uniId = useSelector((state) => state.mainuniversity?.universityId);

  const courseData = useSelector((state) => state.course?.courseInfo);
  const specialDetails = courseData?.specilization_details;
  const universityName = courseData?.univercity_name;
  const manSe = courseData?.student_manse;
  // const fetchMainCourse = async () => {
  //   try {
  //     if (courseName ) {
  //       const res = await fetch(
  //         `${apiBaseUrl}course-details/${universityId}/${courseName}`
  //       );
  //       const data = await res.json();
  //       if (data.status === "success") {
  //         dispatch(setCourse({ ...data }));
  //         // dispatch(setBannerArr(data?.banner))
  //       }
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  // useEffect(() => {
  //   if (courseName ) {
  //     fetchMainCourse();
  //   }
  // }, [courseName]);

  const fetchCourse = async () => {
    try {
      if (courseName) {
        const res = await fetch(
          `${apiBaseUrl}couse-specialization-details/${courseName}`
        );
        const data = await res.json();
        if (data.status === "success") {
          dispatch(setCourse({ ...data }));
          // dispatch(setBannerArr(data?.banner))
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (courseName) {
      fetchCourse();
    }
  }, [courseName]);

  const getUniversityDetails = async () => {
    await fetch(`${apiBaseUrl}univercity-details/${universityName}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          dispatch(setUniversity(data));
          dispatch(setUniversityId(data?.univercity_details?.id));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (universityName) {
      getUniversityDetails();
    }
  }, [universityName]);

  const list = [
    "/images/courses/hits-bca-2.jpg",
    "/images/courses/hits-bca-3.jpg",
    "/images/courses/hits-bca-4.jpg",
  ];

  const subheaderContent = [
    {
      title: "Overview",
      link: "#overview",
    },
    {
      title: "Offers",
      link: "#offers",
    },
    {
      title: "Specification",
      link: "#Specification",
    },
    {
      title: "Summary",
      link: "#summary",
    },
    {
      title: "Brochure",
      link: "#Brochure",
    },
    {
      title: "Compare",
      link: "#Compare",
    },
    {
      title: "Expert Review",
      link: "#Expert_Review",
    },
    {
      title: "faculty",
      link: "#faculty",
    },
    {
      title: " Student Mann Se",
      link: "#Student_Mann_Se",
    },
    {
      title: "  Latest Update",
      link: "#Latest_Update",
    },
    {
      title: "Blogs",
      link: "#Blogs",
    },
    {
      title: "Faq's",
      link: "#Faqs",
    },
    {
      title: "Expert Advice",
      link: "#Expert_Advice",
    },
    {
      title: "Contact",
      link: "#review_wrapper",
    },
  ];

  const courseList = [
    {
      coursename: specialDetails?.name,
      specialization: "",
      totalfees: specialDetails?.fees_amount,
    },
  ];

  const specificationOpt = [
    {
      title: "Gyan Score",
      value:
        specialDetails?.gyan_score === "" || specialDetails?.gyan_score === null
          ? "-"
          : specialDetails?.gyan_score,
    },
    {
      title: "Placement Assistance",
      value:
        specialDetails?.placement === "" || specialDetails?.placement === null
          ? "-"
          : specialDetails?.placement,
    },
    {
      title: "Education Model",
      value:
        specialDetails?.education_model === "" ||
        specialDetails?.education_model === null
          ? "-"
          : specialDetails?.education_model,
    },
    {
      title: "Loan and EMI",
      value:
        specialDetails?.loan_emi === "" || specialDetails?.loan_emi === null
          ? "-"
          : specialDetails?.loan_emi,
    },
    {
      title: "Alumni Status",
      value:
        specialDetails?.alumni_status === "" ||
        specialDetails?.alumni_status === null
          ? "-"
          : specialDetails?.alumni_status,
    },
    {
      title: "UG Rating",
      value:
        specialDetails?.ug_rating === "" || specialDetails?.ug_rating === null
          ? "-"
          : specialDetails?.ug_rating,
    },
  ];

  const subheaderRef = useRef(null);
  useEffect(() => {
    if (subheaderRef.current) {
      navScroller({
        wrapperSelector: subheaderRef.current,
        selector: ".nav-scroller",
        contentSelector: ".nav-scroller-content",
        buttonLeftSelector: ".nav-scroller-btn--left",
        buttonRightSelector: ".nav-scroller-btn--right",
        scrollStep: 75,
      });
    }
  }, []);

  return (
    <CommonLayout>
      <>
        <CourseNameDetails
          courseName={specialDetails?.name}
          universityName={`${courseData?.univercity_name}-${courseData?.course_name}`}
          reviewCount={specialDetails?.total_review}
        />
        <SubHeader subheaderContent={subheaderContent} />
        <JulyOfferWithCarousel
          isSingleImg={true}
          imgSrc={specialDetails?.image}
          details={specialDetails && specialDetails}
        />
        {/* Course */}
        <div id="Courses_All" className={`${CrStyle.course} course`}>
          <div className="container">
            <div className="row">
              {/* Course */}
              <div className="col-lg-8">
                <div className={CrStyle.course_container}>
                  <GetAssistanceBox
                    whatabout={`Contact About ${courseData?.course_name} in  ${specialDetails?.name}`}
                    subtext="Get in touch with a University Authorized Representative"
                    buttonName="Get Assistance"
                  />
                  <UgcAdd imgSrc={`${baseUrl}${uniDetails?.small_banner}`} />Overview

                  {/*-University VIEW MORE / LESS---*/}
                  <UniversitySummary
                    mainId="summary"
                    mainclassName="univer_Summary mt-4"
                    title="Course Summary "
                    defaultShow={specialDetails?.short_summary}
                    details={specialDetails?.summary}
                  />

                  {/*-University VIEW MORE / LESS---*/}

                  <GetAssistanceBox
                    whatabout="University Online Brochure"
                    subtext={`Download ${specialDetails?.name} in Just one click to view all course & Fee`}
                    buttonName="Download Brochure"
                    downloadFile={specialDetails?.online_broucher}
                    buttonclassName="col-lg-4"
                    textColclassName="col-lg-8"
                    mainclassName="mt-5"
                    id="Brochure"
                  />

                  <CourseFee courseList={courseList} />

                  <UniversitySummary
                    mainId="Expert_Review"
                    mainclassName="uni_Expert_wrapper mt-5"
                    title="Course Online Expert Review"
                    defaultShow={specialDetails?.short_online_expert_review}
                    details={specialDetails?.online_expert_review}
                  />

                  <FacultyDetails
                    text={specialDetails?.faculty}
                    facultyList={courseData?.univercity_faculty}
                    uniLogo={`${baseUrl}${uniDetails?.logo}`}
                  />
                </div>
              </div>
              {/* Course Sidebar */}
              <div className="col-lg-4">
                <CourseSideBar details={uniDetails && uniDetails}/>
              </div>
            </div>
          </div>
        </div>
        {/*--------8 *4 Section END---------*/}
        {/*----------FULL Width Section Start----------*/}
        {/*--------Student Mann Se--------*/}
        <StudentManse2 details={manSe && manSe} />
        {/*-Latest Update--*/}
        <LatestUpdates condition={uniId && uniId} />
        {/* Blogs */}
        <Blogs uniId={uniId && uniId} />
        {/*--- Top Universities in India -----*/}
        <TopUniversity />
        {/*--- Top Universities in India -----*/}
        {/*--- FAQ's about Hindustan University -----*/}
        <Faqs
          details={courseData?.course_faq}
          uniname={courseData?.university_name}
        />
        {/*--- Review Section University -----*/}
        <WriteAReview />
        {/*---GET  Testimonials  ---*/}
        <ExpertAdvice getModal="#modalLoginForm2" />
        <InNews />
        <NewsLetter />
      </>
    </CommonLayout>
  );
}
