import React from "react";
import { useSelector } from "react-redux";

export default function Counter() {
  const alluniversity = useSelector((state)=>state.allUniversity?.allUniversity)
  return (
    <>
      <div className="counter">
        <div
          className="counter_background"
          style={{ backgroundImage: "url(images/counter-1.jpg)" }}
        />
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="gif-wrap">
              <img className="counter-money" src="images/money-ug.gif" alt=""/>
            </div>
          </div>
          <div
            className="row counter_form"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="fill_height">
              <div className="col fill_height">
                <div className="counter_form_title">Check Course Fee</div>
                <form
                  className="counter_form_content d-flex flex-column align-items-center justify-content-center"
                  action="#"
                >
                  <select
                    name="university_select"
                    id="university_select"
                    className="counter_input counter_options"
                  >
                    <option>All University</option>
                    {
                      alluniversity?.map((item,index)=>(
                        <option key={index} className="courseSelect" value={item.name}>{item.name}</option>

                      ))
                    }
                    {/* <option
                      className="courseSelect"
                      value="Dr-APJ-Abdul-Kalam-University"
                    >
                      Dr-APJ-Abdul-Kalam-University
                    </option>
                    <option
                      className="courseSelect"
                      value="Sri-Satya-Sai-University-of-Technology-and-Medical-Sciences"
                    >
                      Sri-Satya-Sai-University-of-Technology-and-Medical-Sciences
                    </option>
                    <option
                      className="courseSelect"
                      value="Maharishi-Dayanand-University"
                    >
                      Maharishi-Dayanand-University
                    </option> */}
                  </select>
                  <div className="morecourseShow">
                    <input placeholder="Type Courses" type="text" />
                  </div>
                  {/*- <select name="courses_select" id="courses_select" className=counter_input counter_options select_hide">
                        <option className=Global National Public">Select Course</option>
                        <option className=AA">AA</option>
                        <option className=BB">BB</option>
                        <option className=CC">CC</option>
                     </select>*/}
                  <button type="submit" className="counter_form_button">
                    submit now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
