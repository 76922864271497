import React, { useContext, useEffect, useState } from "react";
import { apiBaseUrl, baseUrl } from "../config";
import AppContext from "../Context/AppContext";
import axios from "axios";
import { data } from "jquery";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { useNavigate } from "react-router-dom";

const HomeSlider = () => {
  const navigate = useNavigate();
  // const csrfToken = document
  //   .querySelector('meta[name="csrf-token"]')
  //   .getAttribute("content");
  const [bannerImg, setBannerImg] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]); 

  const searchDynamicPlaceholder = ["Course", "University", "Specialization"];

  const [currentPlaceholder, setCurrentPlaceholder] = useState(
    searchDynamicPlaceholder[0]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex =
        (searchDynamicPlaceholder.indexOf(currentPlaceholder) + 1) %
        searchDynamicPlaceholder.length;
      setCurrentPlaceholder(searchDynamicPlaceholder[nextIndex]);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [searchDynamicPlaceholder]);

  const { isMobile } = useContext(AppContext);
  

 

  function handleSearchClick(type,keyword) {
    if (type === "univercity") {
      navigate(`/university/${keyword}`);
    }
    if (type === "course") {
      navigate("/course");
    }
    if(type === "specialization"){
      navigate("/general-specialization-course")
    }
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      fetch(`${apiBaseUrl}search-auto-suggest/${searchValue}`)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setSearchList(data);
          }
        }).catch((err) => console.error(err));
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchValue]);

  const fetchBannerImage = () => {
    fetch(`${apiBaseUrl}home-banner`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success" && data.banner_list.length > 0) {
          setBannerImg(data.banner_list[0]);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => console.error("Error fetching banner image:", error));
  };

  useEffect(() => {
    fetchBannerImage();
  }, []);

  const homeSliderStyle = {
    banner: {
      backgroundImage: `url(${baseUrl}${
        isMobile ? bannerImg?.mobile_image : bannerImg.image
      })`,
      backgroundSize: "cover", // Ensure the background covers the entire container
      backgroundPosition: "center", // Center the background image
    },
  };

  return (
    <>
      {/* Home */}
      <div className="home">
        <div className="home_slider_container">
          <div className="home-banner">
            <div
              className="home_slider_background"
              style={homeSliderStyle.banner}
            />
            {/* Home Slider */}
            <div className="home_slider_content">
              <div className="container">
                <div className="row">
                  <div className="col text-center">
                    <div className="content-wrapper">
                      <div className="home_slider_title">
                        Find The Right Course &amp; University
                      </div>
                      <div className="home_slider_subtitle">
                        Future Of Education Technology
                      </div>
                      <div className="desktop-serchbar">
                        <div className="col-11 col-md-10 mx-auto col">
                          <form
                            action="#"
                            id="home_search_form"
                            className="home_search_form d-flex flex-lg-row flex-column align-items-center justify-content-between"
                          >
                            <div className="home-srchform d-flex flex-row align-items-center justify-content-start">
                              
                              <input
                                type="search"
                                className="home_search_input"
                                placeholder={`Keyword Search "${currentPlaceholder}"`}
                                required="required"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                              {searchList.length > 0 && searchValue !== "" && (
                                <div className="suggestionBox">
                                  {searchList?.map((item, index) => (
                                    <div
                                      key={index}
                                      onClick={() =>
                                        handleSearchClick(
                                          item.type,
                                          item.result_key
                                        )
                                      }
                                      className="suggestKeyword"
                                    >
                                      <div className="suggestSearchIcon">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                      </div>
                                      <div className="keywordText">
                                        <div className="keywordTextCont">
                                          {item.result_key}
                                        </div>
                                        <div className="keywordTextType">
                                          {item.type}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                            <button
                              type="submit"
                              className="home_search_button"
                            >
                              search
                            </button>
                          </form>
                        </div>
                      </div>
                      {/*-Mobile search Start--*/}
                      <div className="mobile-serchbar">
                        <form
                          action="#"
                          id="home_search_form2"
                          className="home_search_form d-flex"
                        >
                          <div className="row home-srchform">
                            <div className="col-12 d-flex">
                              <input
                                type="search"
                                className="home_search_input"
                                placeholder="Select University & Courses"
                                required="required"
                              />
                              <button
                                type="submit"
                                className="home_search_button"
                              >
                                search
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(HomeSlider);
