import React, { useState } from "react";
import MobileHeaderItemAccordion from "./MobileHeaderItemAccordion";
import uniqid from 'uniqid';

const MobileHeaderAccordionMain = ({ content, acc }) => {
  const [itemCont, setItemCont] = useState(content);
  const [currAccordion, setCurrAccordion] = useState(acc);

  return (
    <div className="mobile-header-accordion-main">
      <div
        style={{
          position: "absolute",
          top: "-30px",
          right: "0px",
          fontSize: "16px",
        }}
      >
        {currAccordion}
      </div>
      {content.length > 0 &&
        itemCont &&
        itemCont.map((data) => (
          <MobileHeaderItemAccordion
            key={uniqid()}
            title={data?.name}
            link={data?.link}
            item={data}
          />
        ))}
    </div>
  );
};

export default React.memo(MobileHeaderAccordionMain);
