import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";

export default function WriteAReview() {
  return (
    <div id="review_wrapper" className="review_wrapper section bg-light">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col pb-4">
            <div className="section_title text-left">
              <h2 className="section_title"> Write A Review</h2>
            </div>
          </div>
        </div>
        <div className="row pt-3 pt-md-4">
          <div className={CrStyle.review_form_col}>
            <form action="#" className={CrStyle.review_form}>
              <div className="row">
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Name</div>
                  <input
                    name="consult-name"
                    type="text"
                    className={`${CrStyle["form-control"]} form-control`}
                    required="required"
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>
                    Email
                  </div>
                  <input
                    name="consult-email"
                    type="text"
                    className={`${CrStyle["form-control"]} form-control`}
                    required="required"
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>
                    Mobile
                  </div>
                  <input
                    name="consult-mobile"
                    type="number"
                    className={`${CrStyle["form-control"]} form-control`}
                    required="required"
                  />
                </div>
                <div className="col-lg-6  form-group mt-lg-0 mt-3">
                  <div className={`${CrStyle.form_title} form_title`}>
                    Select Course
                  </div>
                  <select
                    name="popup_select_coure"
                    id="counter_select"
                    className={`${CrStyle["form-control"]} form-control`}
                  >
                    <option>Choose Course</option>
                    <option>Course</option>
                    <option>Course</option>
                    <option>Course</option>
                  </select>
                </div>
                <div className="col-lg-12 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>
                    Message
                  </div>
                  <textarea
                    name="consult-message"
                    rows={5}
                    className={`${CrStyle["form-control"]} form-control`}
                    required="required"
                    defaultValue={""}
                  />
                </div>
                <div>
                  <button type="submit" className="comment_button trans_200">
                    submit now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
