import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css"
export default function SpecializationTable({data}) {
  return (
    <div className={`specialization-table ${Style.general_width} py-5`}>
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col-lg-12">
            <div className="responsive-table">
              <table className={`table responsive-table table-bordered ${Style["border-radius"]} text-center`}>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">No. of universities</th>
                    <th scope="col">Course Duration</th>
                    <th scope="col">Eligibility</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.number_of_univercity}</td>
                    <td>{data.course_duration}</td>
                    <td>{data.eligibility}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
